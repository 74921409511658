import { MainService } from './../../services/main.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  constructor(private api: MainService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.api.post('/results', {
      user_id: 'sadmin'
    }).subscribe(value => {
      console.log(value);
    })
  }
}
