import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MainService } from '../../services/main.service';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();

  register: FormGroup;
  public pageData: any;
  public jobs_list: any;


  constructor(private api: MainService, private fb: FormBuilder, private http: HttpClient) {
    this.register = new FormGroup({
      name: new FormControl(''),
      email: new FormControl(''),
      //mobile: new FormControl(''),
      phone: new FormControl(''),
      job: new FormControl(''),
      experience: new FormControl(''),
      cv: new FormControl(''),
    });
    this.createForm();
  }
  createForm() {
    this.register = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      job: ['', Validators.required],
      experience: ['', Validators.required],
      cv: ['', Validators.required]
    });
  }
  // tslint:disable-next-line: variable-name
  cv_placeholder: string = '';
  cv: string = '';


  ngOnInit() {

    this.api.getJobsPageDesc().subscribe(
      data => {
        //debugger
        // console.log(data["data"]);
        var mlist = data['name'];
        var mnameArr = mlist.split('x');
        console.log(mnameArr);

        if (mnameArr.length > 1) {
          var list = mnameArr[1]
          var nameArr = list.split(',');
          console.log(nameArr);
          data['name'] = nameArr;
        }
        data['mtext'] = mnameArr[0];

        this.pageData = data;
      }, error => {
        console.log(error);
      });

    this.api.getJobsSettingsList().subscribe(
      data => {
        //debugger
        // console.log(data["data"]);
        this.jobs_list = data;
      }, error => {
        console.log(error);
      });

    window.scrollTo(0, 0);
  }


  handleItemImageInput(files: FileList, obj) {
    if (files.item(0).type.split('/')[0] == "image" && files.item(0).size <= 2000000) { //2000000 byte = 2 Mega Byte

      var name = files.item(0).name;
      var type = files.item(0).type;
      // alert(type + "    " + name);
      // debugger;
      this.api.postFile(files.item(0)).subscribe(file => {
      })
    }
    else {
      alert('المرفق يجب ان يكون صورة لا تزيد عن 2 ميجا')
    }
  }
  /* ---------------------- Upload CV base64 ---------------- */
  uploadCV(event) {
    if (event.target.files && event.target.files[0]) {
      this.cv_placeholder = event.target.files[0].name;
      // tslint:disable-next-line: prefer-const
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();
        // tslint:disable-next-line: no-shadowed-variable
        reader.onload = (event: any) => {
          console.log(event.target.result);
          this.cv = event.target.result;
          this.register.controls.cv.setValue(event.target.result);
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }


  onSend(form) {
    console.log(form);
    this.api.SendEmailWithAttach("SendEmailWithAttach", form).subscribe(
      response => {

        Swal.fire({
          title: ' تم الأرسال بنجاح, شكراً لك',
          confirmButtonText: 'نعم',
          icon: 'success'
        });
        this.createForm();

        console.log(response);
      }, error => {
        console.log(error);
        Swal.fire({
          title: ' حدث خطا يرجي المحاوله مره اخر' + '</br>' + 'اذا تكررت نفس المشكلة يرجي ارسال السيره الذاتيه علي البريد الموجود في الصفحه ',
          confirmButtonText: 'نعم',
          icon: 'error'
        });
      });
  }

}
