import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedClass } from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class MainService extends SharedClass {
    constructor(http: HttpClient) {
        super('', http);
    }
}