import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-meetingconf',
  templateUrl: './meetingconf.component.html',
  styleUrls: ['./meetingconf.component.scss']
})
export class MeetingConfComponent implements OnInit {

  pageData: any = {};
  video_url: any = '';
  public v1: any = '';

  constructor(private api: MainService, private router: Router, private domSanitizer: DomSanitizer) {

    switch (this.router.url) {
      case '/meet1': this.getMediaData(1); break;
      case '/meet2': this.getMediaData(2); break;
      case '/meet3': this.getMediaData(3); break;
      case '/meet4': this.getMediaData(4); break;
      case '/meet5': this.getMediaData(5); break;
      case '/meet6': this.getMediaData(6); break;
      case '/meet7': this.getMediaData(7); break;
      case '/meet8': this.getMediaData(8); break;
      case '/meet9': this.getMediaData(9); break;
      case '/meet10': this.getMediaData(10); break;
      case '/meet11': this.getMediaData(11); break;
      case '/meet12': this.getMediaData(12); break;
      case '/meet13': this.getMediaData(13); break;
      case '/meet14': this.getMediaData(14); break;
      case '/meet15': this.getMediaData(15); break;
      case '/meet16': this.getMediaData(16); break;
      case '/meet17': this.getMediaData(17); break;
      case '/meet18': this.getMediaData(18); break;
      case '/meet19': this.getMediaData(19); break;
      case '/meet20': this.getMediaData(20); break;
      case '/meet21': this.getMediaData(21); break;
      case '/meet22': this.getMediaData(22); break;
      case '/meet23': this.getMediaData(23); break;
      case '/meet24': this.getMediaData(24); break;
      case '/meet25': this.getMediaData(25); break;
      case '/meet26': this.getMediaData(26); break;
      case '/meet27': this.getMediaData(27); break;
      case '/meet28': this.getMediaData(28); break;
      case '/meet29': this.getMediaData(29); break;
      case '/meet30': this.getMediaData(30); break;
      case '/meet31': this.getMediaData(31); break;
      case '/meet32': this.getMediaData(32); break;
      case '/meet33': this.getMediaData(33); break;
      case '/meet34': this.getMediaData(34); break;
      case '/meet35': this.getMediaData(35); break;
      case '/meet36': this.getMediaData(36); break;
      case '/meet37': this.getMediaData(37); break;
      case '/meet38': this.getMediaData(38); break;
      case '/meet39': this.getMediaData(39); break;
      case '/meet40': this.getMediaData(40); break;
    }
  }
  getMediaData(id) {
    this.api.getMediaMeetByID(id).subscribe(
      data => {
        //debugger
        console.log(data);
       // alert(data["name"]);
        var list = data['images'];
        var urls = list.split(',');
        // alert(nameArr[0]);
        data['images'] = urls;

 this.video_url = this.domSanitizer.bypassSecurityTrustResourceUrl(data['videos']);
if (data['videos'] != "")
  this.v1 = true;
else this.v1 = false;

        this.pageData = data;

      }, error => {
        console.log(error);
      });

  }
  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
