import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  pageData: any = {};
  video_url: any = '';
  public v1: any = '';

  constructor(private api: MainService, private router: Router, private domSanitizer: DomSanitizer) {

    switch (this.router.url) {
      case '/train1': this.getMediaData(1); break;
      case '/train2': this.getMediaData(2); break;
      case '/train3': this.getMediaData(3); break;
      case '/train4': this.getMediaData(4); break;
      case '/train5': this.getMediaData(5); break;
      case '/train6': this.getMediaData(6); break;
      case '/train7': this.getMediaData(7); break;
      case '/train8': this.getMediaData(8); break;
      case '/train9': this.getMediaData(9); break;
      case '/train10': this.getMediaData(10); break;
      case '/train11': this.getMediaData(11); break;
      case '/train12': this.getMediaData(12); break;
      case '/train13': this.getMediaData(13); break;
      case '/train14': this.getMediaData(14); break;
      case '/train15': this.getMediaData(15); break;
      case '/train16': this.getMediaData(16); break;
      case '/train17': this.getMediaData(17); break;
      case '/train18': this.getMediaData(18); break;
      case '/train19': this.getMediaData(19); break;
      case '/train20': this.getMediaData(20); break;
      case '/train21': this.getMediaData(21); break;
      case '/train22': this.getMediaData(22); break;
      case '/train23': this.getMediaData(23); break;
      case '/train24': this.getMediaData(24); break;
      case '/train25': this.getMediaData(25); break;
      case '/train26': this.getMediaData(26); break;
      case '/train27': this.getMediaData(27); break;
      case '/train28': this.getMediaData(28); break;
      case '/train29': this.getMediaData(29); break;
      case '/train30': this.getMediaData(30); break;
      case '/train31': this.getMediaData(31); break;
      case '/train32': this.getMediaData(32); break;
      case '/train33': this.getMediaData(33); break;
      case '/train34': this.getMediaData(34); break;
      case '/train35': this.getMediaData(35); break;
      case '/train36': this.getMediaData(36); break;
      case '/train37': this.getMediaData(37); break;
      case '/train38': this.getMediaData(38); break;
      case '/train39': this.getMediaData(39); break;
      case '/train40': this.getMediaData(40); break;


    }
  }
  getMediaData(id) {
    this.api.getMediaTrainByID(id).subscribe(
      data => {
        //debugger
        console.log(data);
       // alert(data["name"]);
        var list = data['images'];
        var urls = list.split(',');
        // alert(nameArr[0]);
        data['images'] = urls;

        this.video_url = this.domSanitizer.bypassSecurityTrustResourceUrl(data['videos']);
        if (data['videos'] != "")
          this.v1 = true;
        else this.v1 = false;

        this.pageData = data;

      }, error => {
        console.log(error);
      });

  }
  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
