import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-graduated',
  templateUrl: './graduated.component.html',
  styleUrls: ['./graduated.component.scss']
})
export class GraduatedComponent implements OnInit {

  pageData: any = {};
  video_url: any = '';
  public v1: any = '';

  constructor(private api: MainService, private router: Router, private domSanitizer: DomSanitizer) {

    switch (this.router.url) {
      case '/grad1': this.getMediaData(1); break;
      case '/grad2': this.getMediaData(2); break;
      case '/grad3': this.getMediaData(3); break;
      case '/grad4': this.getMediaData(4); break;
      case '/grad5': this.getMediaData(5); break;
      case '/grad6': this.getMediaData(6); break;
      case '/grad7': this.getMediaData(7); break;
      case '/grad8': this.getMediaData(8); break;
      case '/grad9': this.getMediaData(9); break;
      case '/grad10': this.getMediaData(10); break;
      case '/grad11': this.getMediaData(11); break;
      case '/grad12': this.getMediaData(12); break;
      case '/grad13': this.getMediaData(13); break;
      case '/grad14': this.getMediaData(14); break;
      case '/grad15': this.getMediaData(15); break;
      case '/grad16': this.getMediaData(16); break;
      case '/grad17': this.getMediaData(17); break;
      case '/grad18': this.getMediaData(18); break;
      case '/grad19': this.getMediaData(19); break;
      case '/grad20': this.getMediaData(20); break;
      case '/grad21': this.getMediaData(21); break;
      case '/grad22': this.getMediaData(22); break;
      case '/grad23': this.getMediaData(23); break;
      case '/grad24': this.getMediaData(24); break;
      case '/grad25': this.getMediaData(25); break;
      case '/grad26': this.getMediaData(26); break;
      case '/grad27': this.getMediaData(27); break;
      case '/grad28': this.getMediaData(28); break;
      case '/grad29': this.getMediaData(29); break;
      case '/grad30': this.getMediaData(30); break;
      case '/grad31': this.getMediaData(31); break;
      case '/grad32': this.getMediaData(32); break;
      case '/grad33': this.getMediaData(33); break;
      case '/grad34': this.getMediaData(34); break;
      case '/grad35': this.getMediaData(35); break;
      case '/grad36': this.getMediaData(36); break;
      case '/grad37': this.getMediaData(37); break;
      case '/grad38': this.getMediaData(38); break;
      case '/grad39': this.getMediaData(39); break;
      case '/grad40': this.getMediaData(40); break;


    }
  }
  getMediaData(id) {
    this.api.getMediaGradByID(id).subscribe(
      data => {
        //debugger
        console.log(data);
       // alert(data["name"]);
        var list = data['images'];
        var urls = list.split(',');
        // alert(nameArr[0]);
        data['images'] = urls;

        this.video_url = this.domSanitizer.bypassSecurityTrustResourceUrl(data['videos']);
        if (data['videos'] != "")
          this.v1 = true;
        else this.v1 = false;

        this.pageData = data;

      }, error => {
        console.log(error);
      });

  }
  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
