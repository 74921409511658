import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/internal/operators';
import { debug } from 'util';

export class SharedClass {
  url: string = '';
  http: HttpClient;
  mailUrl: string = '';
  constructor(url, http) {
    this.http = http;
    this.url = environment.url + url;
    this.mailUrl = environment.mailUrl;
  }

  //==================================================================================================================================//
  //Start Pages

  //||| Home page |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  //Numbers 
  getNumbers() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetNumbers", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  //Partners In home
  getPartnersInhome() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllPartnersListInHome", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  //Images Slider
  getImagesSlider() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllImagesSlider", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  // Full Contactus In Home
  getContactusInHome() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllBranchesInHome", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  //Header and Footer phones numbers and social media links
  getHeaderFooter() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetCommunSettings", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //||| Vision page |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  getVision() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetVisionByID", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  //||| Sections page |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  getSectionsList() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllSectionsList", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  getSectionByID(id) {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetSectionByID?id=" + id, { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  //||| Media page |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  getMediaBuildList() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllMediaBuildList", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  getMediaBuildByID(id) {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetMediaBuildByID?id=" + id, { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  getMediaGradList() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllMediaGradList", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  getMediaGradByID(id) {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetMediaGradByID?id=" + id, { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  getMediaMeetList() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllMediaMeetList", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  getMediaMeetByID(id) {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetMediaMeetByID?id=" + id, { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  getMediaTrainList() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllMediaTrainList", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  getMediaTrainByID(id) {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetMediaTrainByID?id=" + id, { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  //||| Education Sys page and application page ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  getEducationSys() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetEducationSys", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  //||| FAQ page |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  getFAQ() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllFAQ", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  //||| Partners page |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  getPartners() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllPartnersList", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  //||| Jobs page |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  SendEmailWithAttach(url, resource) {
    let headers = this.setHeaders("application/json");
    let x = this.http.post(this.url + "SaveFormWithAttach", JSON.stringify(resource), { headers: headers }).pipe(catchError(this.handleError));
    //alert(x);
    return x;
  }
  postFile(fileToUpload: File): any {
    let headers = this.setHeaders("image/jpeg");//application/pdf
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    let x = this.http.post(this.mailUrl + "Uploads", fileToUpload, { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  getJobsSettingsList() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllJobsSettingsList", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  getJobsPageDesc() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetJobsPageDesc", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }


  //||| graduates page |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  getStudopinionsList() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllStudopinionsList", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  //||| Contactus page |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
  getContactusInfo() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.url + "GetAllBranches", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  // Send form by Email 
  SendEmailContactus(url, resource) {
    let headers = this.setHeaders("application/json");
    let x = this.http.post(this.url + "SaveFormContactus", JSON.stringify(resource), { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }


  //End Pages\\
  //==================================================================================================================================//


  //==================================================================================================================================//
  //Fill Registration form

  getSection() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.mailUrl + "getSection", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  getBranches() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.mailUrl + "getBranches", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  getAcquaintance() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.mailUrl + "getAcquaintance", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

 getYears() {
    let list: number[] = [1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2];
    var currentYear = new Date().getFullYear();
    //alert(currentYear);

    list[0] = currentYear;
    for (var i = 1; i < 20; i++) {
      currentYear = currentYear - 1;
      list[i] = currentYear;
      //alert(list[i]);
   }
   return list;
  }
  getQualification() {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.mailUrl + "getQualification", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  getGovernorate() {
    // alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post<any[]>(this.mailUrl + "getGovernorate", { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }
  //End Fill Registration form\\
  //==================================================================================================================================//

  //==================================================================================================================================//
  //Start COMMON FUNCTIONS

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  createEnquiry(resource) {
    //alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    //headers.append('Authorization', 'Bearer ${AuthService.getToken()}')
    let x = this.http.post(this.mailUrl + "createEnquiry", JSON.stringify(resource), { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  fireAlert(type, title, text) {
    Swal.fire({
      icon: type,
      title,
      text,
      confirmButtonText: 'إغلاق'
    });
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setHeaders(contentType) {
    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', contentType);
    return headers;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Error handling 
  handleError(error) {
    console.log(error);

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      console.log(error.message);
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // NOT USED |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  SendEmail(url, resource) {
    alert(this.mailUrl);
    let headers = this.setHeaders("application/json");
    let x = this.http.post(this.mailUrl + "SaveForm", JSON.stringify(resource), { headers: headers }).pipe(catchError(this.handleError));
    return x;
  }

  getPages(id): Observable<any> {
    return this.http.get<any>(this.url + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  addEmail(localUrl, data): Observable<Applicant> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    console.log(localUrl + "\n" + data);
    return this.http.post<Applicant>(localUrl, JSON.stringify(data), httpOptions)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  get(path, params?) {
    //console.log(params);
    var x = this.http.get<any>(this.url + path, { params: { ...params } }).pipe(
      retry(1),
      catchError(this.handleError)
    )
    //console.log(x);
    //this.handleError(x);
    return x;
  }

  post(path, data?) {
    return this.http.post<any>(path, data)
      .pipe(
        catchError(this.handleError)
      );
  }

}
//End COMMON FUNCTIONS\\
//==================================================================================================================================//

//==================================================================================================================================//
//Start ABSTRACT CLASSES
export class Pages {
  //date: string;
  //summary: string;
  //temperatureC:number
  //temperatureF: number
  content: string;
}
export class Applicant {
  name: string;
  address: string;
  mobile: string;
  phone: string;
  qualify: string;
  major: string;
  connect: string;
}
export class Governorate {
  id: string;
  name: string;
}
export class RegForm {
  nationalId: string;
  name: string;
  qualification: string;
  graduateYear: string;
  sectionId: string;
  branchId: string;
  grade: string;
  acquaintanceWay: string;
  phone: string;
  stateId: string;
  cityId: string;
}
//End ABSTRACT CLASSES\\
//==================================================================================================================================//

