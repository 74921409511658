import { Router } from '@angular/router';
import { MainService } from './../../services/main.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {


  register: FormGroup;
  constructor(private api: MainService, private router: Router) {
    this.register = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }


  onSend(form) {
    this.api.post('login/', form).subscribe(data => {
      console.log(data);
    }, error => {
      console.log(error);

    })
    console.log(form);
    Swal.fire({
      title: 'شكراً لك',
      confirmButtonText: 'نعم',
      icon: 'success'
    });
    this.router.navigateByUrl('results');
  }
}
