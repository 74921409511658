import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn, rollIn, lightSpeedIn } from 'ng-animate';
import * as AOS from 'aos';
import * as $ from 'jquery';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-graduates',
  templateUrl: './graduates.component.html',
  styleUrls: ['./graduates.component.scss']
})
export class GraduatesComponent implements OnInit {
  // Variables
  modalRef: BsModalRef;
  // Title and Sub-Title
  // 1
  titleLetters: any = 'أكثر من 28,693 خريجين'.split('');
  titleText = '';
  showFunctionTitle: any;
  // 2
  titleLetters2: any = 'من طلبة الأكاديمية حتى الأن'.split('');
  titleText2 = '';
  showFunctionTitle2: any;
  // 3
  subTitleLetters: any = 'أكاديمية 6 أكتوبر الأولى في التعليم الفني في مصر'.split(
    ''
  );
  subTitleText: any = '';
  showFunctionSubTitle: any;

  teacherNums = 0;
  counter = 0;
  teacherFunction: any;
  carouselOptions: any;
  register: FormGroup;
  public govs: any[];
  public quals: any[];
  public sections: any[];
  public branches: any[];
  public conns: any[];

  public pageData: any;
  public years: any[];

  constructor(private api: MainService, private router: Router, private modalService: BsModalService, private fb: FormBuilder) {
    this.register = new FormGroup({
      nationalId: new FormControl(''),
      name: new FormControl(''),
      qualification: new FormControl(''),
      graduateYear: new FormControl(''),
      stateId: new FormControl(''),
      sectionId: new FormControl(''),
      branchId: new FormControl(''),
      acquaintanceWay: new FormControl(''),
      phone: new FormControl(''),
      cityId: new FormControl(''),
      grade: new FormControl(''),
    });
    this.createForm();

      this.api.getStudopinionsList().subscribe(
      data => {
        //debugger
        // console.log(data["data"]);
        this.pageData = data;
      }, error => {
        console.log(error);
      });
  }

  createForm() {
    this.register = this.fb.group({
      nationalId: ['', Validators.required],
      name: ['', Validators.required],
      qualification: ['', Validators.required],
      graduateYear: ['', Validators.required],
      sectionId: ['', Validators.required],
      branchId: ['', Validators.required],
      grade: "222",
      acquaintanceWay: ['', Validators.required],
      phone: ['', Validators.required],
      stateId: ['', Validators.required],
      cityId: "3",
    });
  }

  loadData() {

    // public years: any[];
    this.years = this.api.getYears();
    //console.log(this.years);

    this.api.getSection().subscribe(
      data => {
        //debugger
        // console.log(data["data"]);
        this.sections = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getBranches().subscribe(
      data => {
        //debugger
        // console.log(data["data"]);
        this.branches = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getAcquaintance().subscribe(
      data => {
        //debugger
        //console.log(data["data"]);
        this.conns = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getQualification().subscribe(
      data => {
        //debugger
        //  console.log(data["data"]);
        this.quals = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getGovernorate().subscribe(
      data => {
        //debugger
        // console.log(data["data"]);
        this.govs = data["data"];
      }, error => {
        console.log(error);
      });

  
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  onSend(form) {
    console.log(form);
    this.api.createEnquiry(form).subscribe(
      response => {
        //debugger
        // console.log(response["status"]);
        Swal.fire({
          title: 'شكراً لك',
          confirmButtonText: 'نعم',
          icon: 'success'
        });
        this.createForm();

        this.modalRef.hide();
        console.log(response);
      }, error => {
        console.log(error);
        Swal.fire({
          title: 'حدث خطا يرجي المحاوله مره اخر',
          confirmButtonText: 'نعم',
          icon: 'error'
        });
      });
  }


  ngOnInit() {
    this.loadData();

    // AOS
    AOS.init();
    window.scrollTo(0, 0);
    // Title And Sub-Title
    // 1
    setTimeout(() => {
      console.log(this.titleLetters2);
      let i = 0;
      this.showFunctionTitle2 = setInterval(() => {
        this.titleText2 = this.titleText2 + this.titleLetters2[i];
        document.getElementById('title-2').innerHTML = this.titleText2;
        i++;
        if (i > this.titleLetters2.length - 1) {
          clearInterval(this.showFunctionTitle2);
        }
      }, 30);
    }, 2000);
    // 2
    setTimeout(() => {
      console.log(this.titleLetters);
      let i = 0;
      this.showFunctionTitle = setInterval(() => {
        this.titleText = this.titleText + this.titleLetters[i];
        document.getElementById('title-1').innerHTML = this.titleText;
        i++;
        if (i > this.titleLetters.length - 1) {
          clearInterval(this.showFunctionTitle);
        }
      }, 80);
    }, 800);
    // 3
    setTimeout(() => {
      console.log(this.subTitleLetters);
      let i = 0;
      this.showFunctionSubTitle = setInterval(() => {
        this.subTitleText = this.subTitleText + this.subTitleLetters[i];
        document.getElementById('subTitle').innerHTML = this.subTitleText;
        i++;

        if (i > this.subTitleLetters.length - 1) {
          clearInterval(this.showFunctionSubTitle);
        }
      }, 50);
    }, 2200);

    this.carouselOptions = {
      margin: 5,
      nav: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        400: {
          items: 1,
          nav: true
        },
        500: {
          items: 1,
          nav: true
        },
        600: {
          items: 1,
          nav: true
        },
        700: {
          items: 1,
          nav: true
        },
      }
    };
  }
}
