import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit {

  url: string = '';
  constructor(private router: Router) {
    console.log(router.url);
    this.url = router.url;
  }

  ngOnInit() {
  }

}
