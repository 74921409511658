import { Component, OnInit, TemplateRef } from '@angular/core';
import * as AOS from 'aos';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2'
import { template } from '@angular/core/src/render3';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  modalRef: BsModalRef;
  register: FormGroup;
  public govs: any[];
  public quals: any[];
  public sections: any[];
  public branches: any[];
  public conns: any[];

  pageData: any = {};
  AddInFooter: any = {};

  constructor(private api: MainService,private modalService: BsModalService, private fb: FormBuilder) {
    this.register = new FormGroup({
      nationalId: new FormControl(''),
      name: new FormControl(''),
      qualification: new FormControl(''),
      graduateYear: new FormControl(''),
      stateId: new FormControl(''),
      sectionId: new FormControl(''),
      branchId: new FormControl(''),
      acquaintanceWay: new FormControl(''),
      phone: new FormControl(''),
      cityId: new FormControl(''),
      grade: new FormControl(''),
    });
    this.createForm();
  }

  createForm() {
    this.register = this.fb.group({
      nationalId: ['', Validators.required],
      name: ['', Validators.required],
      qualification: ['', Validators.required],
      graduateYear: ['', Validators.required],
      sectionId: ['', Validators.required],
      branchId: ['', Validators.required],
      grade: "222",
      acquaintanceWay: ['', Validators.required],
      phone: ['', Validators.required],
      stateId: ['', Validators.required],
      cityId: "3",
    });
  }

  loadData() {

    this.api.getContactusInHome().subscribe(
      data => {
        //debugger
        console.log(data);
        this.AddInFooter = data;
      }, error => {
        console.log(error);
    });

    this.api.getHeaderFooter().subscribe(
      data => {
        //debugger
        console.log(data);
        this.pageData = data;
      }, error => {
        console.log(error);
      });

    this.api.getSection().subscribe(
      data => {
        //debugger
       // console.log(data["data"]);
        this.sections = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getBranches().subscribe(
      data => {
        //debugger
       // console.log(data["data"]);
        this.branches = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getAcquaintance().subscribe(
      data => {
        //debugger
        //console.log(data["data"]);
        this.conns = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getQualification().subscribe(
      data => {
        //debugger
      //  console.log(data["data"]);
        this.quals = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getGovernorate().subscribe(
      data => {
        //debugger
       // console.log(data["data"]);
        this.govs = data["data"];
      }, error => {
        console.log(error);
      });

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  onSend(form) {
    console.log(form);
    this.api.createEnquiry(form).subscribe(
      response => {
        //debugger
       // console.log(response["status"]);
        Swal.fire({
          title: 'شكراً لك',
          confirmButtonText: 'نعم',
          icon: 'success'
        });
        this.createForm();

        this.modalRef.hide();
        console.log(response);
      }, error => {
        console.log(error);
        Swal.fire({
          title: 'حدث خطا يرجي المحاوله مره اخر',
          confirmButtonText: 'نعم',
          icon: 'error'
        });
    });
  
  }


  ngOnInit() {

    this.loadData();


    // AOS
    AOS.init();
  }
}
