import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MainService } from '../../services/main.service';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contact: FormGroup;
  pageData: any = {};
  iframes: any = {};


  constructor(private api: MainService, private fb: FormBuilder, private domSanitizer: DomSanitizer) {
    this.contact = new FormGroup({
      name: new FormControl(''),
      email: new FormControl(''),
      message: new FormControl(''),
    });
    this.createForm();
    //  alert("hello");
    // this.loadData();
  }

  createForm() {
    this.contact = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required]
    });
  }



  loadData() {

    this.api.getContactusInfo().subscribe(
      data => {
        //debugger
        //console.log("00000000000" + data[0].mapurl);
        //alert(data[0].mapurl);
        //this.iframe.nativeElement.setAttribute('src', data[0].mapurl);

        // this.iframe.nativeElement.setAttribute('src', data[0].mapurl);
        this.pageData = data;
       
        for (var i = 0; i < data.length; i++) {
          this.iframes[i] = this.domSanitizer.bypassSecurityTrustResourceUrl(data[i].mapurl);
        }
       // this.iframe0 = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pageData[0].mapurl);

        //$('#myframe').attr("src", data[0].mapurl);
      //  document.getElementById("iframe0").setAttribute("src", this.iframe0);
      }, error => {
        console.log(error);
      });
  }

  ngOnInit() {
    this.loadData();
   
    window.scrollTo(0, 0);
  }
  ngDoCheck() {


  }

  sendContact(form) {
    console.log(form);
    this.api.SendEmailContactus("SaveForm", form).subscribe(
      response => {
        //debugger
      }, error => {
        console.log(error);

      });
    Swal.fire({
      title: 'شكراً لك',
      confirmButtonText: 'نعم',
      icon: 'success'
    });
    this.createForm();
  }

}
