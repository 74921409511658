import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-majors',
  templateUrl: './majors.component.html',
  styleUrls: ['./majors.component.scss']
})

export class MajorsComponent implements OnInit {
  pageData: any = {};

  constructor(private api: MainService) { }

  loadData() {

    this.api.getSectionsList().subscribe(
      data => {
        //debugger
        console.log(data);
        this.pageData = data;
      }, error => {
        console.log(error);
      });
  }
  ngOnInit() {

    this.loadData();

    window.scrollTo(0, 0);
  }

}
