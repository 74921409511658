import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  pageData: any = {};
  sectionsList: any = {};

  constructor(private api: MainService) { }

  loadData() {

    this.api.getHeaderFooter().subscribe(
      data => {
        //debugger
        console.log(data);
        this.pageData = data;
      }, error => {
        console.log(error);
    });


    this.api.getSectionsList().subscribe(
      data => {
        //debugger
        console.log(data);
        this.sectionsList = data;
      }, error => {
        console.log(error);
      });
  }


  ngOnInit() {
    this.loadData();

    $(document).ready(() => {
      $(window).scroll(() => {
        if ($(document).scrollTop() > 0) {
          $('.navbar').addClass('shrink');
        } else {
          $('.navbar').removeClass('shrink');
        }
      });
    });
  }

}
