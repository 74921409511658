import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './views/reusable-components/home/home.component';
import { RegComponent } from './views/reusable-components/reg/reg.component';//////////////////////////
import { FooterComponent } from './views/reusable-components/footer/footer.component';
import { NavBarComponent } from './views/reusable-components/nav-bar/nav-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Animation

// Owl Carousal
import { OwlModule } from 'ngx-owl-carousel';

// Angular Material
import { MatIconModule } from '@angular/material/icon';
import { EducationSystemComponent } from './views/education-system/education-system.component';
import { QuestionsComponent } from './views/questions/questions.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { GraduatesComponent } from './views/graduates/graduates.component';
import { DepartmentComponent } from './views/department/department.component';
import { MediaComponent } from './views/media/media.component';
import { VisionComponent } from './views/vision/vision.component';
import { PartnersComponent } from './views/partners/partners.component';
import { MajorsComponent } from './views/majors/majors.component';
import { ApplicationComponent } from './views/application/application.component';

import { BuildingImgsComponent } from './views/building-imgs/building-imgs.component';
import { BuildingComponent } from './views/building/building.component';

import { GraduatedImgsComponent } from './views/graduated-imgs/graduated-imgs.component';
import { GraduatedComponent } from './views/graduated/graduated.component';

import { MeetingsComponent } from './views/meetings/meetings.component';
import { MeetingConfComponent } from './views/meetingconf/meetingconf.component';

import { TrainingImgsComponent } from './views/training-imgs/training-imgs.component';
import { TrainingComponent } from './views/training/training.component';


import { JobsComponent } from './views/jobs/jobs.component';
import { StudentsComponent } from './views/students/students.component';
import { ResultComponent } from './views/result/result.component';
import { TablesComponent } from './views/tables/tables.component';
import { InterceptorService } from './services/interceptor.service';
import { RegistrationComponent } from './views/registration/registration.component';

import { BuildingTantaComponent } from './views/building-tanta/building-tanta.component';
import { Graduated2018Component } from './views/graduated-2018/graduated-2018.component';
import { Graduated2019Component } from './views/graduated-2019/graduated-2019.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent, /////////////////////////////
    RegComponent,
    FooterComponent,
    NavBarComponent,
    EducationSystemComponent,
    QuestionsComponent,
    ContactUsComponent,
    MediaComponent,
    GraduatesComponent,
    DepartmentComponent,
    VisionComponent,
    PartnersComponent,
    MajorsComponent,
    ApplicationComponent,

    JobsComponent,
    StudentsComponent,
    ResultComponent,
    TablesComponent,
    RegistrationComponent,

    GraduatedImgsComponent,
    BuildingImgsComponent,
    MeetingsComponent,
    TrainingImgsComponent,

    BuildingComponent,
    GraduatedComponent,
    MeetingConfComponent,
    TrainingComponent,

    BuildingTantaComponent,
    Graduated2018Component,
    Graduated2019Component
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    HttpClientModule,
    ModalModule.forRoot(),
    OwlModule
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: InterceptorService,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
