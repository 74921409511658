import { Component } from '@angular/core';

//import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
//import { RegComponent } from './views/reusable-components/reg/reg.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'OctobarAcademy';

  //constructor(public matDialog: MatDialog) { }

  //openModal() {
  //  const dialogConfig = new MatDialogConfig();
  //  // The user can't close the dialog by clicking outside its body
  //  dialogConfig.disableClose = true;
  //  dialogConfig.id = "modal-component";
  //  dialogConfig.height = "350px";
  //  dialogConfig.width = "600px";
  //  // https://material.angular.io/components/dialog/overview
  //  const modalDialog = this.matDialog.open(RegComponent, dialogConfig);
  //}
}
