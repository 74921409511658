import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss']
})
export class BuildingComponent implements OnInit {

  pageData: any = {};
  video_url: any = '';
  public v1: any = '';

  constructor(private api: MainService, private router: Router, private domSanitizer: DomSanitizer) {

    switch (this.router.url) {
      case '/build1': this.getMediaData(1); break;
      case '/build2': this.getMediaData(2); break;
      case '/build3': this.getMediaData(3); break;
      case '/build4': this.getMediaData(4); break;
      case '/build5': this.getMediaData(5); break;
      case '/build6': this.getMediaData(6); break;
      case '/build7': this.getMediaData(7); break;
      case '/build8': this.getMediaData(8); break;
      case '/build9': this.getMediaData(9); break;
      case '/build10': this.getMediaData(10); break;
      case '/build11': this.getMediaData(11); break;
      case '/build12': this.getMediaData(12); break;
      case '/build13': this.getMediaData(13); break;
      case '/build14': this.getMediaData(14); break;
      case '/build15': this.getMediaData(15); break;
      case '/build16': this.getMediaData(16); break;
      case '/build17': this.getMediaData(17); break;
      case '/build18': this.getMediaData(18); break;
      case '/build19': this.getMediaData(19); break;
      case '/build20': this.getMediaData(20); break;
      case '/build21': this.getMediaData(21); break;
      case '/build22': this.getMediaData(22); break;
      case '/build23': this.getMediaData(23); break;
      case '/build24': this.getMediaData(24); break;
      case '/build25': this.getMediaData(25); break;
      case '/build26': this.getMediaData(26); break;
      case '/build27': this.getMediaData(27); break;
      case '/build28': this.getMediaData(28); break;
      case '/build29': this.getMediaData(29); break;
      case '/build30': this.getMediaData(30); break;
      case '/build31': this.getMediaData(31); break;
      case '/build32': this.getMediaData(32); break;
      case '/build33': this.getMediaData(33); break;
      case '/build34': this.getMediaData(34); break;
      case '/build35': this.getMediaData(35); break;
      case '/build36': this.getMediaData(36); break;
      case '/build37': this.getMediaData(37); break;
      case '/build38': this.getMediaData(38); break;
      case '/build39': this.getMediaData(39); break;
      case '/build40': this.getMediaData(40); break
    }
  }
  getMediaData(id) {
    this.api.getMediaBuildByID(id).subscribe(
      data => {
        //debugger
        console.log(data);
       // alert(data["name"]);
        var list = data['images'];
        var urls = list.split(',');
        // alert(nameArr[0]);
        data['images'] = urls;
       // data['videos'] = this.domSanitizer.bypassSecurityTrustResourceUrl(data['videos']);
        this.video_url = this.domSanitizer.bypassSecurityTrustResourceUrl(data['videos']);
        if (data['videos'] != "")
          this.v1 = true;
        else this.v1 = false;
        this.pageData = data;

      }, error => {
        console.log(error);
      });

  }
  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
