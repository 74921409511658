import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  pageData: any = {};

  constructor(private api: MainService, private sanitizer: DomSanitizer) { }

  loadData() {

    this.api.getPartners().subscribe(
      data => {
        //debugger
        console.log(data);
       // let sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data['image_name']);
       // console.log(sanitizedUrl);
       // data['image_name'] = sanitizedUrl;
        this.pageData = data;
      }, error => {
        console.log(error);
      });
  }


  ngOnInit() {
    this.loadData();
    window.scrollTo(0, 0);
  }

}
