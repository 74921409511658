import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  modalRef: BsModalRef;
  register: FormGroup;
  pageData: any = {};

  constructor(private api: MainService,private modalService: BsModalService) {
    this.register = new FormGroup({
      name: new FormControl(''),
      address: new FormControl(''),
      mobile: new FormControl(''),
      phone: new FormControl(''),
      qualify: new FormControl(''),
      major: new FormControl(''),
      connect: new FormControl(''),
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  loadData() {

    this.api.getFAQ().subscribe(
      data => {
        //debugger
        console.log(data);
        this.pageData = data;
      }, error => {
        console.log(error);
      });
  }
  ngOnInit() {

    this.loadData();

    window.scrollTo(0, 0);
  }

  onSend(form, template: TemplateRef<any>) {
    console.log(form);

    this.api.SendEmail("https://localhost:44354/api/EmailSender/SaveForm", form).subscribe(
      response => {
        //debugger
      }, error => {
        console.log(error);

    });

    Swal.fire({
      title: 'شكراً لك',
      confirmButtonText: 'نعم',
      icon: 'success'
    });
    this.modalRef.hide();
  }
}
