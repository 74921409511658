import { TablesComponent } from './views/tables/tables.component';
import { ResultComponent } from './views/result/result.component';
import { StudentsComponent } from './views/students/students.component';
import { JobsComponent } from './views/jobs/jobs.component';

import { ApplicationComponent } from './views/application/application.component';
import { MajorsComponent } from './views/majors/majors.component';
import { PartnersComponent } from './views/partners/partners.component';
import { VisionComponent } from './views/vision/vision.component';
import { DepartmentComponent } from './views/department/department.component';
import { GraduatesComponent } from './views/graduates/graduates.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { QuestionsComponent } from './views/questions/questions.component';
import { EducationSystemComponent } from './views/education-system/education-system.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/reusable-components/home/home.component';
import { MediaComponent } from './views/media/media.component';
import { RegistrationComponent } from './views/registration/registration.component';

import { BuildingImgsComponent } from './views/building-imgs/building-imgs.component';
import { GraduatedImgsComponent } from './views/graduated-imgs/graduated-imgs.component';
import { MeetingsComponent } from './views/meetings/meetings.component';


import { BuildingComponent } from './views/building/building.component';
import { GraduatedComponent } from './views/graduated/graduated.component';
import { MeetingConfComponent } from './views/meetingconf/meetingconf.component';


import { BuildingTantaComponent } from './views/building-tanta/building-tanta.component';
import { Graduated2018Component } from './views/graduated-2018/graduated-2018.component';
import { Graduated2019Component } from './views/graduated-2019/graduated-2019.component';
import { TrainingComponent } from './views/training/training.component';
import { TrainingImgsComponent } from './views/training-imgs/training-imgs.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'education-system',
    component: EducationSystemComponent
  },
  {
    path: 'questions-and-answers',
    component: QuestionsComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'media',
    component: MediaComponent
  },
  {
    path: 'graduates',
    component: GraduatesComponent
  },
  {
    path: 'hotels',
    component: DepartmentComponent
  },
  {
    path: 'petrols',
    component: DepartmentComponent
  },
  {
    path: 'khyadia',
    component: DepartmentComponent
  },
  {
    path: 'medical',
    component: DepartmentComponent
  },
  {
    path: 'tv',
    component: DepartmentComponent
  },
  {
    path: 'business',
    component: DepartmentComponent
  },
  {
    path: 'it',
    component: DepartmentComponent
  },
  {
    path: 'width',
    component: DepartmentComponent
  },
  {
    path: 'electronic',
    component: DepartmentComponent
  },
  {
    path: 'sec1',
    component: DepartmentComponent
  },
  {
    path: 'sec2',
    component: DepartmentComponent
  },
  {
    path: 'sec3',
    component: DepartmentComponent
  },
  {
    path: 'sec4',
    component: DepartmentComponent
  },
  {
    path: 'sec4',
    component: DepartmentComponent
  },
  {
    path: 'sec6',
    component: DepartmentComponent
  },
  {
    path: 'sec7',
    component: DepartmentComponent
  },
  {
    path: 'sec8',
    component: DepartmentComponent
  },
  {
    path: 'sec9',
    component: DepartmentComponent
  },
  {
    path: 'sec10',
    component: DepartmentComponent
  },
  {
    path: 'sec11',
    component: DepartmentComponent
  },
  {
    path: 'sec12',
    component: DepartmentComponent
  },
  {
    path: 'sec13',
    component: DepartmentComponent
  },
  {
    path: 'sec14',
    component: DepartmentComponent
  },
  {
    path: 'sec15',
    component: DepartmentComponent
  },
  {
    path: 'sec16',
    component: DepartmentComponent
  },
  {
    path: 'sec17',
    component: DepartmentComponent
  },
  {
    path: 'sec18',
    component: DepartmentComponent
  },
  {
    path: 'sec19',
    component: DepartmentComponent
  },
  {
    path: 'sec20',
    component: DepartmentComponent
  },
  {
    path: 'sec21',
    component: DepartmentComponent
  },
  {
    path: 'sec22',
    component: DepartmentComponent
  },
  {
    path: 'sec23',
    component: DepartmentComponent
  },
  {
    path: 'sec24',
    component: DepartmentComponent
  },
  {
    path: 'sec25',
    component: DepartmentComponent
  },
  {
    path: 'vision',
    component: VisionComponent
  },
  {
    path: 'partners',
    component: PartnersComponent
  },
  {
    path: 'majors',
    component: MajorsComponent
  },
  {
    path: 'application',
    component: ApplicationComponent
  },
  {
    path: 'graduated-images',
    component: GraduatedImgsComponent
  },
  {
    path: 'building-images',
    component: BuildingImgsComponent
  },
  {
    path: 'meetings',
    component: MeetingsComponent
  },
  {
    path: 'training-images',
    component: TrainingImgsComponent
  },
  {
    path: 'jobs',
    component: JobsComponent
  },
  {
    path: 'students',
    component: StudentsComponent
  },
  {
    path: 'results',
    component: ResultComponent
  },
  {
    path: 'terms',
    component: TablesComponent
  },
  {
    path: 'exams',
    component: TablesComponent
  },
  {
    path: 'reg',
    component: RegistrationComponent
  },
  {
    path: 'haram',
    component: BuildingComponent
  },
  {
    path: 'shubra',
    component: GraduatedComponent
  },
  {
    path: 'tanta',
    component: BuildingTantaComponent
  },
  {
    path: 'grad2018',
    component: Graduated2018Component
  },
  {
    path: 'grad2019',
    component: Graduated2019Component
  },
  {
    path: 'build1', component: BuildingComponent
  },
  {
    path: 'build2', component: BuildingComponent
  },
  {
    path: 'build3', component: BuildingComponent
  },
  {
    path: 'build4', component: BuildingComponent
  },
  {
    path: 'build5', component: BuildingComponent
  },
  {
    path: 'build6', component: BuildingComponent
  },
  {
    path: 'build7', component: BuildingComponent
  },
  {
    path: 'build8', component: BuildingComponent
  },
  {
    path: 'build9', component: BuildingComponent
  },
  {
    path: 'build10', component: BuildingComponent
  },
  {
    path: 'build11', component: BuildingComponent
  },
  {
    path: 'build12', component: BuildingComponent
  },
  {
    path: 'build13', component: BuildingComponent
  },
  {
    path: 'build14', component: BuildingComponent
  },
  {
    path: 'build15', component: BuildingComponent
  },
  {
    path: 'build16', component: BuildingComponent
  },
  {
    path: 'build17', component: BuildingComponent
  },
  {
    path: 'build18', component: BuildingComponent
  },
  {
    path: 'build19', component: BuildingComponent
  },
  {
    path: 'build20', component: BuildingComponent
  },
  {
    path: 'build21', component: BuildingComponent
  },
  {
    path: 'build22', component: BuildingComponent
  },
  {
    path: 'build23', component: BuildingComponent
  },
  {
    path: 'build24', component: BuildingComponent
  },
  {
    path: 'build25', component: BuildingComponent
  },
  {
    path: 'build26', component: BuildingComponent
  },
  {
    path: 'build27', component: BuildingComponent
  },
  {
    path: 'build28', component: BuildingComponent
  },
  {
    path: 'build29', component: BuildingComponent
  },
  {
    path: 'build30', component: BuildingComponent
  },
  {
    path: 'build31', component: BuildingComponent
  },
  {
    path: 'build32', component: BuildingComponent
  },
  {
    path: 'build33', component: BuildingComponent
  },
  {
    path: 'build34', component: BuildingComponent
  },
  {
    path: 'build35', component: BuildingComponent
  },
  {
    path: 'build36', component: BuildingComponent
  },
  {
    path: 'build37', component: BuildingComponent
  },
  {
    path: 'build38', component: BuildingComponent
  },
  {
    path: 'build39', component: BuildingComponent
  },
  {
    path: 'build40', component: BuildingComponent
  },


  {
    path: 'grad1', component: GraduatedComponent
  },
  {
    path: 'grad2', component: GraduatedComponent
  },
  {
    path: 'grad3', component: GraduatedComponent
  },
  {
    path: 'grad4', component: GraduatedComponent
  },
  {
    path: 'grad5', component: GraduatedComponent
  },
  {
    path: 'grad6', component: GraduatedComponent
  },
  {
    path: 'grad7', component: GraduatedComponent
  },
  {
    path: 'grad8', component: GraduatedComponent
  },
  {
    path: 'grad9', component: GraduatedComponent
  },
  {
    path: 'grad10', component: GraduatedComponent
  },
  {
    path: 'grad11', component: GraduatedComponent
  },
  {
    path: 'grad12', component: GraduatedComponent
  },
  {
    path: 'grad13', component: GraduatedComponent
  },
  {
    path: 'grad14', component: GraduatedComponent
  },
  {
    path: 'grad15', component: GraduatedComponent
  },
  {
    path: 'grad16', component: GraduatedComponent
  },
  {
    path: 'grad17', component: GraduatedComponent
  },
  {
    path: 'grad18', component: GraduatedComponent
  },
  {
    path: 'grad19', component: GraduatedComponent
  },
  {
    path: 'grad20', component: GraduatedComponent
  },
  {
    path: 'grad21', component: GraduatedComponent
  },
  {
    path: 'grad22', component: GraduatedComponent
  },
  {
    path: 'grad23', component: GraduatedComponent
  },
  {
    path: 'grad24', component: GraduatedComponent
  },
  {
    path: 'grad25', component: GraduatedComponent
  },
  {
    path: 'grad26', component: GraduatedComponent
  },
  {
    path: 'grad27', component: GraduatedComponent
  },
  {
    path: 'grad28', component: GraduatedComponent
  },
  {
    path: 'grad29', component: GraduatedComponent
  },
  {
    path: 'grad30', component: GraduatedComponent
  },
  {
    path: 'grad31', component: GraduatedComponent
  },
  {
    path: 'grad32', component: GraduatedComponent
  },
  {
    path: 'grad33', component: GraduatedComponent
  },
  {
    path: 'grad34', component: GraduatedComponent
  },
  {
    path: 'grad35', component: GraduatedComponent
  },
  {
    path: 'grad36', component: GraduatedComponent
  },
  {
    path: 'grad37', component: GraduatedComponent
  },
  {
    path: 'grad38', component: GraduatedComponent
  },
  {
    path: 'grad39', component: GraduatedComponent
  },
  {
    path: 'grad40', component: GraduatedComponent
  },

  {
    path: 'meet1', component: MeetingConfComponent
  },
  {
    path: 'meet2', component: MeetingConfComponent
  },
  {
    path: 'meet3', component: MeetingConfComponent
  },
  {
    path: 'meet4', component: MeetingConfComponent
  },
  {
    path: 'meet5', component: MeetingConfComponent
  },
  {
    path: 'meet6', component: MeetingConfComponent
  },
  {
    path: 'meet7', component: MeetingConfComponent
  },
  {
    path: 'meet8', component: MeetingConfComponent
  },
  {
    path: 'meet9', component: MeetingConfComponent
  },
  {
    path: 'meet10', component: MeetingConfComponent
  },
  {
    path: 'meet11', component: MeetingConfComponent
  },
  {
    path: 'meet12', component: MeetingConfComponent
  },
  {
    path: 'meet13', component: MeetingConfComponent
  },
  {
    path: 'meet14', component: MeetingConfComponent
  },
  {
    path: 'meet15', component: MeetingConfComponent
  },
  {
    path: 'meet16', component: MeetingConfComponent
  },
  {
    path: 'meet17', component: MeetingConfComponent
  },
  {
    path: 'meet18', component: MeetingConfComponent
  },
  {
    path: 'meet19', component: MeetingConfComponent
  },
  {
    path: 'meet20', component: MeetingConfComponent
  },
  {
    path: 'meet21', component: MeetingConfComponent
  },
  {
    path: 'meet22', component: MeetingConfComponent
  },
  {
    path: 'meet23', component: MeetingConfComponent
  },
  {
    path: 'meet24', component: MeetingConfComponent
  },
  {
    path: 'meet25', component: MeetingConfComponent
  },
  {
    path: 'meet26', component: MeetingConfComponent
  },
  {
    path: 'meet27', component: MeetingConfComponent
  },
  {
    path: 'meet28', component: MeetingConfComponent
  },
  {
    path: 'meet29', component: MeetingConfComponent
  },
  {
    path: 'meet30', component: MeetingConfComponent
  },
  {
    path: 'meet31', component: MeetingConfComponent
  },
  {
    path: 'meet32', component: MeetingConfComponent
  },
  {
    path: 'meet33', component: MeetingConfComponent
  },
  {
    path: 'meet34', component: MeetingConfComponent
  },
  {
    path: 'meet35', component: MeetingConfComponent
  },
  {
    path: 'meet36', component: MeetingConfComponent
  },
  {
    path: 'meet37', component: MeetingConfComponent
  },
  {
    path: 'meet38', component: MeetingConfComponent
  },
  {
    path: 'meet39', component: MeetingConfComponent
  },
  {
    path: 'meet40', component: MeetingConfComponent
  },


  {
    path: 'train1', component: TrainingComponent
  },
  {
    path: 'train2', component: TrainingComponent
  },
  {
    path: 'train3', component: TrainingComponent
  },
  {
    path: 'train4', component: TrainingComponent
  },
  {
    path: 'train5', component: TrainingComponent
  },
  {
    path: 'train6', component: TrainingComponent
  },
  {
    path: 'train7', component: TrainingComponent
  },
  {
    path: 'train8', component: TrainingComponent
  },
  {
    path: 'train9', component: TrainingComponent
  },
  {
    path: 'train10', component: TrainingComponent
  },
  {
    path: 'train11', component: TrainingComponent
  },
  {
    path: 'train12', component: TrainingComponent
  },
  {
    path: 'train13', component: TrainingComponent
  },
  {
    path: 'train14', component: TrainingComponent
  },
  {
    path: 'train15', component: TrainingComponent
  },
  {
    path: 'train16', component: TrainingComponent
  },
  {
    path: 'train17', component: TrainingComponent
  },
  {
    path: 'train18', component: TrainingComponent
  },
  {
    path: 'train19', component: TrainingComponent
  },
  {
    path: 'train20', component: TrainingComponent
  },
  {
    path: 'train21', component: TrainingComponent
  },
  {
    path: 'train22', component: TrainingComponent
  },
  {
    path: 'train23', component: TrainingComponent
  },
  {
    path: 'train24', component: TrainingComponent
  },
  {
    path: 'train25', component: TrainingComponent
  },
  {
    path: 'train26', component: TrainingComponent
  },
  {
    path: 'train27', component: TrainingComponent
  },
  {
    path: 'train28', component: TrainingComponent
  },
  {
    path: 'train29', component: TrainingComponent
  },
  {
    path: 'train30', component: TrainingComponent
  },
  {
    path: 'train31', component: TrainingComponent
  },
  {
    path: 'train32', component: TrainingComponent
  },
  {
    path: 'train33', component: TrainingComponent
  },
  {
    path: 'train34', component: TrainingComponent
  },
  {
    path: 'train35', component: TrainingComponent
  },
  {
    path: 'train36', component: TrainingComponent
  },
  {
    path: 'train37', component: TrainingComponent
  },
  {
    path: 'train38', component: TrainingComponent
  },
  {
    path: 'train39', component: TrainingComponent
  },
  {
    path: 'train40', component: TrainingComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
