import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit {
  pageData: any = {};

  constructor(private api: MainService) { }

  loadData() {

    this.api.getMediaMeetList().subscribe(
      data => {
        //debugger
        console.log(data);
        this.pageData = data;
      }, error => {
        console.log(error);
      });
  }
  ngOnInit() {

    this.loadData();
    window.scrollTo(0, 0);
  }

}
