// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//http://diuonlien.us/api/api/profile/

export const environment = {
  production: false,
  url: "https://backend-test.6oa.edu.eg/api/CMS/",
  mailUrl: "https://backend-test.6oa.edu.eg/api/EmailSender/"
};

/*
 *
 url: "https://backend-test.6oa.edu.eg/api/CMS/",
  mailUrl: "https://backend-test.6oa.edu.eg/api/EmailSender/"

  url: "http://dashboard.apps366.com/api/CMS/",

  url: "http://localhost:52973/",
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
//https://localhost:44354/api/CMSPages?id=
//http://wp.apps366.com/


//  mailUrl: "https://localhost:44354/api/EmailSender/",
//url: "https://localhost:44354/api/CMSPages?id="

//url: "http://wp.apps366.com/api/CMSPages?id=",
//  mailUrl: "http://wp.apps366.com/api/EmailSender/"
