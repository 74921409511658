import { Router } from '@angular/router';
import { MainService } from './../../services/main.service';

import { Component, OnInit } from '@angular/core';

import { Pages } from '../../services/shared.service';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.scss']
})
export class VisionComponent implements OnInit {
  pageData: any = {};

  constructor(private api: MainService, private router: Router, private sanitizer: DomSanitizer) {
    // console.log("===========constructor=========\n");
    // this.api.get('637240750527180803', "id").subscribe((data: {}) => {
    //    this.pageData = data;

    // console.log("====================\n" + this.pageData);
    //   })

   // this.api.getPages('637240750527180803').subscribe((data: {}) => {
   //   this.pageData = data;// sanitizer.bypassSecurityTrustHtml(data[0]);

      //console.log(data)
   // })

  }
  //abstract bypassSecurityTrustHtml(value: string): SafeHtml

  loadData() {

    this.api.getVision().subscribe(
      data => {
        //debugger
        console.log(data);
        this.pageData = data;
      }, error => {
        console.log(error);
      });
  }
  ngOnInit() {
    
    this.loadData();


    window.scrollTo(0, 0);
  }
}
