import { Router } from '@angular/router';
import { MainService } from './../../services/main.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit {
  register: FormGroup;
  public govs: any[];
  public quals: any[];
  public sections: any[];
  public branches: any[];
  public conns: any[];
  public years: any[];

  constructor(private api: MainService, private fb: FormBuilder) {
    this.register = new FormGroup({
      nationalId: new FormControl(''),
      name: new FormControl(''),
      qualification: new FormControl(''),
      graduateYear: new FormControl(''),
      stateId: new FormControl(''),
      sectionId: new FormControl(''),
      branchId: new FormControl(''),
      acquaintanceWay: new FormControl(''),
      phone: new FormControl(''),
      cityId: new FormControl(''),
      grade: new FormControl(''),
    });
    this.createForm();
  }

  createForm() {
    this.register = this.fb.group({
      nationalId: ['', Validators.required],
      name: ['', Validators.required],
      qualification: ['', Validators.required],
      graduateYear: ['', Validators.required],
      sectionId: ['', Validators.required],
      branchId: ['', Validators.required],
      grade: "222",
      acquaintanceWay: ['', Validators.required],
      phone: ['', Validators.required],
      stateId: ['', Validators.required],
      cityId: "3",
    });
  }

  loadData() {
    // public years: any[];
    this.years = this.api.getYears();
    //console.log(this.years);

    this.api.getSection().subscribe(
      data => {
        //debugger
        // console.log(data["data"]);
        this.sections = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getBranches().subscribe(
      data => {
        //debugger
        // console.log(data["data"]);
        this.branches = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getAcquaintance().subscribe(
      data => {
        //debugger
        //console.log(data["data"]);
        this.conns = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getQualification().subscribe(
      data => {
        //debugger
        //  console.log(data["data"]);
        this.quals = data["data"];
      }, error => {
        console.log(error);
      });

    this.api.getGovernorate().subscribe(
      data => {
        //debugger
        // console.log(data["data"]);
        this.govs = data["data"];
      }, error => {
        console.log(error);
      });

  }

  onSend(form) {
    console.log(form);
    this.api.createEnquiry(form).subscribe(
      response => {
        //debugger
        // console.log(response["status"]);
        Swal.fire({
          title: 'شكراً لك',
          confirmButtonText: 'نعم',
          icon: 'success'
        });
        this.createForm();

        console.log(response);
      }, error => {
        console.log(error);
        Swal.fire({
          title: 'حدث خطا يرجي المحاوله مره اخر',
          confirmButtonText: 'نعم',
          icon: 'error'
        });
      });
  }


  ngOnInit() {
    this.loadData();

    window.scrollTo(0, 0);
  }
}
