import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn, rollIn, lightSpeedIn } from 'ng-animate';
import * as AOS from 'aos';
import * as $ from 'jquery';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'app-reg',
  templateUrl: './reg.component.html',
  styleUrls: ['./reg.component.scss']
})
export class RegComponent implements OnInit {
  // Variables
  modalRef: BsModalRef;

  subTitleText: any = '';
  showFunctionSubTitle: any;

  teacherNums = 0;
  counter = 0;
  teacherFunction: any;
  carouselOptions: any;
  register: FormGroup;


  constructor(private api: MainService,private router: Router, private modalService: BsModalService, private fb: FormBuilder) {
    this.register = new FormGroup({
      name: new FormControl(''),
      address: new FormControl(''),
      mobile: new FormControl(''),
      phone: new FormControl(''),
      qualify: new FormControl(''),
      major: new FormControl(''),
      connect: new FormControl(''),
    });
    this.createForm();
  }

  createForm() {
    this.register = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      mobile: ['', Validators.required],
      phone: ['', Validators.required],
      qualify: ['', Validators.required],
      major: ['', Validators.required],
      connect: ['', Validators.required]
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  onSend(form) {
    console.log(form);
    this.api.SendEmail("https://localhost:44354/api/EmailSender/SaveForm", form).subscribe(
      response => {
        //debugger
      }, error => {
        console.log(error);

    });

    Swal.fire({
      title: 'شكراً لك',
      confirmButtonText: 'نعم',
      icon: 'success'
    });
    this.createForm();

    this.modalRef.hide();
  }

  ngOnInit() {
    // AOS
    AOS.init();
    window.scrollTo(0, 0);

  }
}
